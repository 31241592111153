.cards {
    display: grid;
    gap: 1rem;
}

.cards--three-column {
    grid-template-columns: 100%;
}

.card.card--podologia {
    background-image: url('../assets/podologia-square.png');
}

.card.card--promocao::before {
    content: '';
    background-image: url('../assets/home_cadeira_promocao.jpeg');
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    transition: filter linear .1s;
}

.card.card--promocao:hover::before {
    filter: opacity(10%);
}

.card.card--entrega::before {
    content: '';
    background-image: url('../assets/Entrega_domicilio.jpg');
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    transition: filter linear .1s;
}

.card.card--entrega:hover::before {
    filter: opacity(10%);
}

.card {
    padding: 1rem;
    height: 40rem;
    border-radius: 1rem;
    transition: all linear .1s;
    background-color: #ffffff;
    overflow: hidden;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    position: relative;
}

.card__title {
    font-size: 1.6rem;
    color: #005f99;
}

.card__text {
    font-size: 1.2rem;
    color: #ffffff;
}

.card__text--dark {
    color: #555555;
}

.card.card--entrega .card__title {
    visibility: hidden;
    opacity: 0%;

    transition: opacity linear .1s;
}

.card.card--entrega .card__text {
    visibility: hidden;
    opacity: 0%;

    transition: opacity linear .1s;
}

.card.card--entrega:hover {
    background-image: none;
}

.card.card--entrega:hover .card__title {
    visibility: visible;
    opacity: 100%;
}

.card.card--entrega:hover .card__text {
    visibility: visible;
    opacity: 100%;
}

.card__image {
    width: 40%;
    margin: 1rem 5%;
}

@media (min-width: 900px) {
    .cards--three-column {
        grid-template-columns: 50% 50%;
    }

    .card:hover {
        z-index: 10;
        transform: scale(1.1);
        box-shadow: 0 15px 20px 0px rgb(0 0 0 / 20%);
    }
}

@media (min-width: 1200px) {
    .cards--three-column {
        grid-template-columns: 33% 34% 33%;
    }
}