@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;1,400;1,700&display=swap');

html, body {
  height: 100vh;
  width: 100%;

  font-size: 10px;
  font-family: 'Lato', sans-serif;
}

body {
  font-size: 1.6rem;
}

* {
  box-sizing: border-box;
}

p, h1, h2, h3, h4, h5, span {
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h2 {
  font-size: 3em;
  line-height: 1em;
  color: #237CA2F4;
  text-align: center;
}

h3 {
  font-size: 2em;
  line-height: 1em;
  color: #237CA2F4;
  text-align: center;
}

h4 {
  font-size: 1.8em;
  line-height: 1em;
  color: #237CA2F4;
  text-align: center;
}

p {
  line-height: 3rem;
  font-size: 1.8rem;
  font-weight: 400;
}

strong, b {
  font-weight: 700;
}

.content {
  padding: 1rem;
  width: 95%;
  max-width: 120rem;
  margin: auto;
}

.button {
  display: block;
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  background-color: #237CA2F4;
  color: #fff;
  border: none;
  cursor: pointer;

  transition: all .1s linear;
}

.button:hover {
  transform: scale(1.01);
  box-shadow: 0 15px 20px 0px rgb(0 0 0 / 20%);
}

.button:active {
  transform: scale(1.01);
  box-shadow: 0 15px 20px 0px rgb(0 0 0 / 20%), 0 15px 20px 0px rgb(0 0 0 / 20%) inset;
}

h3.city-name {
  font-size: 2em;
  line-height: 1rem;
  color: #237CA2F4;
  text-align: center;
}

.contact-container {
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .contact-container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem;
  }
}

.contact-form {
  display: block;
  width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.contact-map {
  position: relative;
  display: none;
  overflow: hidden;
  width: 100%;
  min-height: 30rem;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

@media (min-width: 768px) {
  .contact-map {
    display: block;
  }
}

.contact-map-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.form-group {
  width: inherit;
  margin-bottom: 1rem;
}

.form-group > * {
  width: inherit;
  display: block;
  font-size: 1.6rem;
  margin-bottom: .5rem;
}

.form-group > input, .form-group textarea {
  padding: 1rem;
  border: solid 1px #aaaaaa;
  border-radius: 4px;
  box-shadow: none;
  text-shadow: none;
  text-decoration: none;
  outline: none;
}

.form-group > textarea {
  resize: none;
}

input, textarea {
  transition: border-color .1s linear;
}

input.invalid, textarea.invalid {
  border-color: red;
}

input+.error, textarea+.error {
  height: 0;
  visibility: hidden;
  color: red;
  font-size: 1.2rem;
  opacity: 0;
  padding: 0;

  transition: all .1s linear;
}

input.invalid+.error, textarea.invalid+.error {
  height: 1.5rem;
  visibility: visible;
  opacity: 1;
}

.contact-cities {
  width: 100%;

  font-size: 1.6rem;
  text-align: center;
}

@media (min-width: 768px) {
  .contact-cities {
    display: grid;
    gap: 1rem;
    grid-template-columns: 33% 34% 33%;
  }
}

.contact-city img {
  width: 1.6rem;
}

.quem-somos__img {
  width: 90vw;
  margin: 0 auto;
  padding: .5rem;
  box-shadow: 0 7px 10px 0px rgba(0, 0, 0, 20%);
  background-color: #ffffff;
  display: block;
}

@media (min-width: 768px) {
  .display--flex {
    display: flex;
  }

  .flex--start {
    align-items: start;
  }

  .flex--center {
    align-items: center;
  }

  .margin--right {
    margin-right: 2rem;
  }

  .quem-somos__img {
    width: 32rem;
    height: auto;
  }

  .servicos_group {
    width: 50%;
    padding: 0 2rem;
  }
}

.servicos_img {
  display: block;
  margin: 0 auto;
  width: 10rem;
}

.margin--bottom {
  margin-bottom: 2rem;
}

.produtos {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  padding: 0;
  flex-wrap: wrap;
}

.produtos__item {
  text-decoration: none;
  padding: 2rem;
  display: block;
  border-radius: .4rem;
  font-size: 3rem;
  background-color: #237CA2F4;
  color: #ffffff;
  margin-bottom: 2rem;

  transition: background-color .1s linear, transform .2s linear;
}

.produtos__item:hover {
  transform: scale(1.1);
  background-color: #1A5A76F4;
}

.icones {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;
}

.images {
  display: flex;
  list-style: none;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0;
}

.image {
  padding: 1rem;
  box-shadow: 0 7px 10px 0px rgba(0, 0, 0, 20%);
  background-color: #ffffff;
  margin-bottom: 2rem;
  display: block;
  max-height: 32rem;
  height: 100%;
  width: auto;
  overflow: hidden;
}

.image--large {
  width: 100%;
  max-height: none;
  height: auto;
}
