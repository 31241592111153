.icon_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #25607c;
    text-decoration: none;
    padding: 2rem;
    border-radius: .4rem;
    max-width: 32rem;

    transition: background-color .1s linear, transform .2s linear;
}

.icon_button__img {
    width: 10rem;
    margin-bottom: 2rem;
}

.icon_button:hover {
    transform: scale(1.1);
    background-color: rgba(37, 96, 124, 0.1);
}