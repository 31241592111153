.header {
    background-image: url('../assets/bg-sao-jose-ortopedicos.jpg');
    background-size: cover;
    background-position-x: 80%;
    height: 50rem;
    width: 100%;
    background-repeat: no-repeat;
    position: relative;
    color: #ffffff;
}

.header__selo {
    height: 15rem;
    border-radius: 1rem;
    margin: 1rem 0 0 1rem;
    position: absolute;
}

.header__text {
    font-size: 2.5rem;
    text-align: center;
    margin: 0 auto;
    width: 70%;
    padding-top: 20rem;
}

.header-nav {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.header-nav__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
}

.header-nav__list-item-link:hover {
    background-color: #237CA2F4;
}

.header-nav__list-item-link.active {
    font-weight: 700;
    background-color: transparent;
}

.header-nav__list-item-link {
    font-size: 1.3rem;
    text-decoration: none;
    color: #ffffff;
    padding: 1rem;
    display: block;
    border-radius: 4px;

    transition: background-color .1s linear;
}

.header-nav__list-item.active > .header-nav__list-item-link {
    color: #ffcd39;
}

@media (min-width: 768px) {
    .header__text {
        font-size: 3.5rem;
    }

    .header-nav__list-item-link {
        font-size: 1.8rem;
    }
}

.container {
    max-width: 120rem;
    position: relative;
    margin: 0 auto;
}