.footer {
    background-color: #25607c;
    color: #ffffff;
    text-align: center;
}

.footer__container {
    padding: 1rem;
}

@media (min-width: 768px) {
    .footer__container {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer__container > * {
        flex: 1;
    }
}

.footer .footer__copyright {
    background-color: #084a68;
    padding: 1rem;
    text-align: center;
}

.footer__title {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
}

.footer p {
    font-size: 1.8rem;
    line-height: 2rem;
}

.map-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}

.map-nav__item-link {
    text-decoration: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
    display: block;
}

.footer__social {
    text-align: center;
}

.footer__social-image {
    width: 3rem;
}